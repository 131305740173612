import GlobalServices from '../context/GlobalServices';
import Main from '../element/Main';

const socketPathGenerator = function* (array: string[]) {
    for (let i = 0; i < array.length; i++) {
        yield array[i];
    }
};

export const stagingRoutes = [
    {
        path: 'strawberryapp',
        acctId: 3,
        botId: 31,
        dbProfile: 'mario.staging.1',
        component: (
            <GlobalServices
                acctId={3}
                botId={31}
                apiPaths={['https://staging-app-test.bimariobot.com/nodeApi']}
            >
                <Main
                    acctId={3}
                    botId={31}
                    brandId="welton"
                    dbProfile="mario.staging.1"
                    client="strawberryapp"
                    title="乐天堂客服聊天窗口"
                    apiPaths={['https://staging-app-test.bimariobot.com/nodeApi']}
                    pluginPaths={['https://staging-bot.bimariobot.com/plugin']}
                    socketPaths={['wss://testing-bot.bimariobot.com/socket']}
                />
            </GlobalServices>
        ),
    },
    {
        path: 'blueberryapp',
        acctId: 4,
        botId: 30,
        dbProfile: 'mario.staging.1',
        component: (
            <GlobalServices
                acctId={4}
                botId={30}
                apiPaths={['https://staging-app-test.bimariobot.com/nodeApi']}
            >
                <Main
                    acctId={4}
                    botId={30}
                    client="blueberryapp"
                    title="与民同乐，尽在同乐城"
                    brandId="vpo"
                    dbProfile="mario.staging.1"
                    apiPaths={['https://staging-app-test.bimariobot.com/nodeApi']}
                    pluginPaths={['https://staging-bot.bimariobot.com/plugin']}
                    socketPaths={['wss://staging-bot.bimariobot.com/socket']}
                />
            </GlobalServices>
        ),
    },
    {
        path: 'cranberryapp',
        acctId: 3,
        botId: 41,
        dbProfile: 'mario.staging.1',
        component: (
            <GlobalServices
                acctId={3}
                botId={41}
                apiPaths={['https://staging-app-test.bimariobot.com/nodeApi']}
            >
                <Main
                    acctId={3}
                    botId={41}
                    client="cranberryapp"
                    title="ห้องแชทสดกับนางฟ้า Fun88"
                    brandId="welton"
                    dbProfile="mario.staging.1"
                    apiPaths={['https://staging-app-test.bimariobot.com/nodeApi']}
                    pluginPaths={['https://staging-bot.bimariobot.com/plugin']}
                    socketPaths={['wss://staging-bot.bimariobot.com/socket']}
                />
            </GlobalServices>
        ),
    },
    {
        path: 'mulberryapp',
        acctId: 3,
        botId: 40,
        dbProfile: 'mario.staging.1',
        component: (
            <GlobalServices
                acctId={3}
                botId={40}
                apiPaths={['https://staging-app-test.bimariobot.com/nodeApi']}
            >
                <Main
                    acctId={3}
                    botId={40}
                    client="mulberryapp"
                    title="Hỗ Trợ Trực Tuyến Fun88"
                    brandId="welton"
                    dbProfile="mario.staging.1"
                    apiPaths={['https://staging-app-test.bimariobot.com/nodeApi']}
                    pluginPaths={['https://staging-bot.bimariobot.com/plugin']}
                    socketPaths={['wss://staging-bot.bimariobot.com/socket']}
                />
            </GlobalServices>
        ),
    },
    {
        path: 'bilberryapp',
        acctId: 4,
        botId: 45,
        dbProfile: 'mario.staging.1',
        component: (
            <GlobalServices
                acctId={4}
                botId={45}
                apiPaths={['https://staging-app-test.bimariobot.com/nodeApi']}
            >
                <Main
                    acctId={4}
                    botId={45}
                    client="bilberryapp"
                    title="J1M1 Staging"
                    brandId="vpo"
                    dbProfile="mario.staging.1"
                    apiPaths={['https://staging-app-test.bimariobot.com/nodeApi']}
                    pluginPaths={['https://staging-bot.bimariobot.com/plugin']}
                    socketPaths={['wss://staging-bot.bimariobot.com/socket']}
                />
            </GlobalServices>
        ),
    },
    {
        path: 'kiwiberryapp',
        acctId: 5,
        botId: 38,
        dbProfile: 'mario.staging.1',
        component: (
            <GlobalServices
                acctId={5}
                botId={38}
                apiPaths={['https://staging-app-test.bimariobot.com/nodeApi']}
            >
                <Main
                    acctId={5}
                    botId={38}
                    client="kiwiberryapp"
                    title="必威客服聊天窗口"
                    brandId="masuta"
                    dbProfile="mario.staging.1"
                    apiPaths={['https://staging-app-test.bimariobot.com/nodeApi']}
                    pluginPaths={['https://staging-bot.bimariobot.com/plugin']}
                    socketPaths={['wss://staging-bot.bimariobot.com/socket']}
                />
            </GlobalServices>
        ),
    },
    {
        path: 'raspberryapp',
        acctId: 4,
        botId: 39,
        dbProfile: 'mario.staging.1',
        component: (
            <GlobalServices
                acctId={4}
                botId={39}
                apiPaths={['https://staging-app-test.bimariobot.com/nodeApi']}
            >
                <Main
                    acctId={4}
                    botId={39}
                    client="raspberryapp"
                    title="ห้องแชทออนไลน์กับเจ้าหน้าที่ Betway"
                    brandId="vpo"
                    dbProfile="mario.staging.1"
                    apiPaths={['https://staging-app-test.bimariobot.com/nodeApi']}
                    pluginPaths={['https://staging-bot.bimariobot.com/plugin']}
                    socketPaths={['wss://staging-bot.bimariobot.com/socket']}
                />
            </GlobalServices>
        ),
    },
    {
        path: 'gojiberryapp',
        acctId: 3,
        botId: 47,
        dbProfile: 'mario.staging.1',
        component: (
            <GlobalServices
                acctId={3}
                botId={47}
                apiPaths={['https://staging-app-test.bimariobot.com/nodeApi']}
            >
                <Main
                    acctId={3}
                    botId={47}
                    client="gojiberryapp"
                    title="ห้องแชทสดกับเจ้าหน้าที่ JBO"
                    brandId="welton"
                    dbProfile="mario.staging.1"
                    apiPaths={['https://staging-app-test.bimariobot.com/nodeApi']}
                    pluginPaths={['https://staging-bot.bimariobot.com/plugin']}
                    socketPaths={['wss://staging-bot.bimariobot.com/socket']}
                />
            </GlobalServices>
        ),
    },
    {
        path: 'acaiberryapp',
        acctId: 4,
        botId: 46,
        dbProfile: 'mario.staging.1',
        component: (
            <GlobalServices
                acctId={3}
                botId={46}
                apiPaths={['https://staging-app-test.bimariobot.com/nodeApi']}
            >
                <Main
                    acctId={4}
                    botId={46}
                    client="acaiberryapp"
                    title="J1M3 Staging"
                    brandId="vpo"
                    dbProfile="mario.staging.1"
                    apiPaths={['https://staging-app-test.bimariobot.com/nodeApi']}
                    pluginPaths={['https://staging-bot.bimariobot.com/plugin']}
                    socketPaths={['wss://staging-bot.bimariobot.com/socket']}
                />
            </GlobalServices>
        ),
    },
    {
        path: 'yellowberryapp',
        acctId: 3,
        botId: 49,
        dbProfile: 'mario.staging.1',
        component: (
            <GlobalServices
                acctId={3}
                botId={49}
                apiPaths={['https://staging-app-test.bimariobot.com/nodeApi']}
            >
                <Main
                    acctId={3}
                    botId={49}
                    client="yellowberryapp"
                    title="F1M5 Staging"
                    brandId="welton"
                    dbProfile="mario.staging.1"
                    apiPaths={['https://staging-app-test.bimariobot.com/nodeApi']}
                    pluginPaths={['https://staging-bot.bimariobot.com/plugin']}
                    socketPaths={['wss://staging-bot.bimariobot.com/socket']}
                />
            </GlobalServices>
        ),
    },
    {
        path: 'elderberryapp',
        acctId: 5,
        botId: 60,
        dbProfile: 'mario.staging.1',
        component: (
            <GlobalServices
                acctId={5}
                botId={60}
                apiPaths={['https://staging-app-test.bimariobot.com/nodeApi']}
            >
                <Main
                    acctId={5}
                    botId={60}
                    client="elderberryapp"
                    title="Hỗ Trợ Trực Tuyến Betway"
                    brandId="masuta"
                    dbProfile="mario.staging.1"
                    apiPaths={['https://staging-app-test.bimariobot.com/nodeApi']}
                    pluginPaths={['https://staging-bot.bimariobot.com/plugin']}
                    socketPaths={['wss://staging-bot.bimariobot.com/socket']}
                />
            </GlobalServices>
        ),
    },
    {
        path: 'baneberryapp',
        acctId: 3,
        botId: 61,
        dbProfile: 'mario.staging.1',
        component: (
            <GlobalServices
                acctId={3}
                botId={61}
                apiPaths={['https://staging-app-test.bimariobot.com/nodeApi']}
            >
                <Main
                    acctId={3}
                    botId={61}
                    client="baneberryapp"
                    title="B1MX ENG"
                    brandId="welton"
                    dbProfile="mario.staging.1"
                    apiPaths={['https://staging-app-test.bimariobot.com/nodeApi']}
                    pluginPaths={['https://staging-bot.bimariobot.com/plugin']}
                    socketPaths={['wss://staging-bot.bimariobot.com/socket']}
                />
            </GlobalServices>
        ),
    },
    {
        path: 'caperberryapp',
        acctId: 3,
        botId: 62,
        dbProfile: 'mario.staging.1',
        component: (
            <GlobalServices
                acctId={3}
                botId={62}
                apiPaths={['https://staging-app-test.bimariobot.com/nodeApi']}
            >
                <Main
                    acctId={3}
                    botId={62}
                    client="caperberryapp"
                    title="B1MX CN"
                    brandId="welton"
                    dbProfile="mario.staging.1"
                    apiPaths={['https://staging-app-test.bimariobot.com/nodeApi']}
                    pluginPaths={['https://staging-bot.bimariobot.com/plugin']}
                    socketPaths={['wss://staging-bot.bimariobot.com/socket']}
                />
            </GlobalServices>
        ),
    },
    {
        path: 'redberryapp',
        acctId: 3,
        botId: 48,
        dbProfile: 'mario.staging.1',
        component: (
            <GlobalServices
                acctId={3}
                botId={48}
                apiPaths={['https://staging-app-test.bimariobot.com/nodeApi']}
            >
                <Main
                    acctId={3}
                    botId={48}
                    brandId="welton"
                    dbProfile="mario.staging.1"
                    client="redberryapp"
                    title="MarioGPT Demo"
                    apiPaths={['https://staging-app-test.bimariobot.com/nodeApi']}
                    pluginPaths={['https://staging-bot.bimariobot.com/plugin']}
                    socketPaths={['wss://staging-bot.bimariobot.com/socket']}
                />
            </GlobalServices>
        ),
    },
];
