import GlobalServices from '../context/GlobalServices';
import Main from '../element/Main';

const socketPathGenerator = function* (array: string[]) {
    for (let i = 0; i < array.length; i++) {
        yield array[i];
    }
};

export const productionRoutes = [
    {
        path: 'strawberryapp',
        acctId: 3,
        botId: 4,
        dbProfile: 'mario.production',
        component: (
            <GlobalServices
                acctId={3}
                botId={4}
                apiPaths={['https://strawberryapp.hihi2u.com/nodeApi']}
            >
                <Main
                    acctId={3}
                    botId={4}
                    brandId="welton"
                    dbProfile="mario.production"
                    client="strawberryapp"
                    title="乐天堂客服聊天窗口"
                    apiPaths={['https://strawberryapp.hihi2u.com/nodeApi']}
                    pluginPaths={['https://bot.bimariobot.com/plugin']}
                    socketPaths={['wss://dr1-bot-welton.bimariobot.com/socket',  
                    'wss://dr2-bot-welton.bimariobot.com/socket']}
                />
            </GlobalServices>
        ),
    },
    {
        path: 'blueberryapp',
        acctId: 4,
        botId: 22,
        dbProfile: 'mario.production',
        component: (
            <GlobalServices
                acctId={4}
                botId={22}
                apiPaths={['https://blueberryapp.hihi2u.com/nodeApi']}
            >
                <Main
                    acctId={4}
                    botId={22}
                    client="blueberryapp"
                    title="与民同乐，尽在同乐城"
                    brandId="vpo"
                    dbProfile="mario.production"
                    apiPaths={['https://blueberryapp.hihi2u.com/nodeApi']}
                    pluginPaths={['https://bot.bimariobot.com/plugin']}
                    socketPaths={['wss://dr1-bot-vpo.bimariobot.com/socket', 
                    'wss://dr2-bot-vpo.bimariobot.com/socket']}
                />
            </GlobalServices>
        ),
    },
    {
        path: 'cranberryapp',
        acctId: 3,
        botId: 30,
        dbProfile: 'mario.production',
        component: (
            <GlobalServices
                acctId={3}
                botId={30}
                apiPaths={['https://cranberryapp.hihi2u.com/nodeApi']}
            >
                <Main
                    acctId={3}
                    botId={30}
                    client="cranberryapp"
                    title="ห้องแชทสดกับนางฟ้า Fun88"
                    brandId="welton"
                    dbProfile="mario.production"
                    apiPaths={['https://cranberryapp.hihi2u.com/nodeApi']}
                    pluginPaths={['https://bot.bimariobot.com/plugin']}
                    socketPaths={['wss://dr1-bot-weltonth.bimariobot.com/socket', 
                    'wss://dr2-bot-weltonth.bimariobot.com/socket']}
                />
            </GlobalServices>
        ),
    },
    {
        path: 'mulberryapp',
        acctId: 3,
        botId: 61,
        dbProfile: 'mario.production',
        component: (
            <GlobalServices
                acctId={3}
                botId={61}
                apiPaths={['https://mulberryapp.hihi2u.com/nodeApi']}
            >
                <Main
                    acctId={3}
                    botId={61}
                    client="mulberryapp"
                    title="Hỗ Trợ Trực Tuyến Fun88"
                    brandId="welton"
                    dbProfile="mario.production"
                    apiPaths={['https://mulberryapp.hihi2u.com/nodeApi']}
                    pluginPaths={['https://bot.bimariobot.com/plugin']}
                    socketPaths={['wss://dr1-bot-welton.bimariobot.com/socket', 
                    'wss://dr2-bot-welton.bimariobot.com/socket']}
                />
            </GlobalServices>
        ),
    },
    {
        path: 'bilberryapp',
        acctId: 4,
        botId: 67,
        dbProfile: 'mario.production',
        component: (
            <GlobalServices
                acctId={4}
                botId={67}
                apiPaths={['https://bilberryapp.hihi2u.com/nodeApi']}
            >
                <Main
                    acctId={4}
                    botId={67}
                    client="bilberryapp"
                    title="竞博客服聊天窗口"
                    brandId="vpo"
                    dbProfile="mario.production"
                    apiPaths={['https://bilberryapp.hihi2u.com/nodeApi']}
                    pluginPaths={['https://bot.bimariobot.com/plugin']}
                    socketPaths={['wss://dr1-bot-vpo.bimariobot.com/socket', 
                    'wss://dr2-bot-vpo.bimariobot.com/socket']}
                />
            </GlobalServices>
        ),
    },
    {
        path: 'kiwiberryapp',
        acctId: 5,
        botId: 64,
        dbProfile: 'mario.production',
        component: (
            <GlobalServices
                acctId={5}
                botId={64}
                apiPaths={['https://kiwiberryapp.hihi2u.com/nodeApi']}
            >
                <Main
                    acctId={5}
                    botId={64}
                    client="kiwiberryapp"
                    title="必威客服聊天窗口"
                    brandId="masuta"
                    dbProfile="mario.production"
                    apiPaths={['https://kiwiberryapp.hihi2u.com/nodeApi']}
                    pluginPaths={['https://bot.bimariobot.com/plugin']}
                    socketPaths={['wss://dr1-bot-masuta.bimariobot.com/socket', 
                    'wss://dr2-bot-masuta.bimariobot.com/socket']}
                />
            </GlobalServices>
        ),
    },
    {
        path: 'kiwiberryapp01',
        acctId: 5,
        botId: 64,
        dbProfile: 'mario.production',
        component: (
            <GlobalServices
                acctId={5}
                botId={64}
                apiPaths={['https://kiwiberryapp.hihi2u.com/nodeApi']}
            >
                <Main
                    acctId={5}
                    botId={64}
                    client="kiwiberryapp"
                    title="必威客服聊天窗口"
                    brandId="masuta"
                    dbProfile="mario.production"
                    apiPaths={['https://kiwiberryapp.hihi2u.com/nodeApi']}
                    pluginPaths={['https://bot.bimariobot.com/plugin']}
                    socketPaths={['wss://dr1-bot-masuta.bimariobot.com/socket', 
                    'wss://dr2-bot-masuta.bimariobot.com/socket']}
                />
            </GlobalServices>
        ),
    },
    {
        path: 'raspberryapp',
        acctId: 4,
        botId: 29,
        dbProfile: 'mario.production',
        component: (
            <GlobalServices
                acctId={4}
                botId={29}
                apiPaths={['https://raspberryapp.hihi2u.com/nodeApi']}
            >
                <Main
                    acctId={4}
                    botId={29}
                    client="raspberryapp"
                    title="ห้องแชทออนไลน์กับเจ้าหน้าที่ Kapook"
                    brandId="vpo"
                    dbProfile="mario.production"
                    apiPaths={['https://raspberryapp.hihi2u.com/nodeApi']}
                    pluginPaths={['https://bot.bimariobot.com/plugin']}
                    socketPaths={['wss://dr1-bot-vpo.bimariobot.com/socket', 
                    'wss://dr2-bot-vpo.bimariobot.com/socket']}
                />
            </GlobalServices>
        ),
    },
    {
        path: 'gojiberryapp',
        acctId: 3,
        botId: 74,
        dbProfile: 'mario.production',
        component: (
            <GlobalServices
                acctId={3}
                botId={74}
                apiPaths={['https://gojiberryapp.hihi2u.com/nodeApi']}
            >
                <Main
                    acctId={3}
                    botId={74}
                    client="gojiberryapp"
                    title="ห้องแชทสดกับเจ้าหน้าที่ JBO"
                    brandId="welton"
                    dbProfile="mario.production"
                    apiPaths={['https://gojiberryapp.hihi2u.com/nodeApi']}
                    pluginPaths={['https://bot.bimariobot.com/plugin']}
                    socketPaths={['wss://dr1-bot-welton.bimariobot.com/socket', 
                    'wss://dr2-bot-welton.bimariobot.com/socket']}
                />
            </GlobalServices>
        ),
    },
    {
        path: 'acaiberryapp',
        acctId: 4,
        botId: 72,
        dbProfile: 'mario.production',
        component: (
            <GlobalServices
                acctId={3}
                botId={72}
                apiPaths={['https://acaiberryapp.hihi2u.com/nodeApi']}
            >
                <Main
                    acctId={4}
                    botId={72}
                    client="acaiberryapp"
                    title="Hỗ Trợ Trực Tuyến JBO"
                    brandId="vpo"
                    dbProfile="mario.production"
                    apiPaths={['https://acaiberryapp.hihi2u.com/nodeApi']}
                    pluginPaths={['https://bot.bimariobot.com/plugin']}
                    socketPaths={['wss://dr1-bot-vpo.bimariobot.com/socket', 
                    'wss://dr2-bot-vpo.bimariobot.com/socket']}
                />
            </GlobalServices>
        ),
    },
    {
        path: 'yellowberryapp',
        acctId: 3,
        botId: 75,
        dbProfile: 'mario.production',
        component: (
            <GlobalServices
                acctId={3}
                botId={75}
                apiPaths={['https://yellowberryapp.hihi2u.com/nodeApi']}
            >
                <Main
                    acctId={3}
                    botId={75}
                    client="yellowberryapp"
                    title="Fun88 India"
                    brandId="welton"
                    dbProfile="mario.production"
                    apiPaths={['https://yellowberryapp.hihi2u.com/nodeApi']}
                    pluginPaths={['https://bot.bimariobot.com/plugin']}
                    socketPaths={['wss://dr1-bot-welton.bimariobot.com/socket', 
                    'wss://dr2-bot-welton.bimariobot.com/socket']}
                />
            </GlobalServices>
        ),
    },
    {
        path: 'elderberryapp',
        acctId: 5,
        botId: 80,
        dbProfile: 'mario.production',
        component: (
            <GlobalServices
                acctId={5}
                botId={80}
                apiPaths={['https://elderberryapp.hihi2u.com/nodeApi']}
            >
                <Main
                    acctId={5}
                    botId={80}
                    client="elderberryapp"
                    title="Hỗ Trợ Trực Tuyến Betway"
                    brandId="masuta"
                    dbProfile="mario.production"
                    apiPaths={['https://elderberryapp.hihi2u.com/nodeApi']}
                    pluginPaths={['https://bot.bimariobot.com/plugin']}
                    socketPaths={['wss://dr1-bot-masuta.bimariobot.com/socket', 
                    'wss://dr2-bot-masuta.bimariobot.com/socket']}
                />
            </GlobalServices>
        ),
    },
    {
        path: 'baneberryapp',
        acctId: 3,
        botId: 81,
        dbProfile: 'mario.production',
        component: (
            <GlobalServices
                acctId={3}
                botId={81}
                apiPaths={['https://baneberryapp.hihi2u.com/nodeApi']}
            >
                <Main
                    acctId={3}
                    botId={81}
                    client="baneberryapp"
                    title="BTC365"
                    brandId="welton"
                    dbProfile="mario.production"
                    apiPaths={['https://baneberryapp.hihi2u.com/nodeApi']}
                    pluginPaths={['https://bot.bimariobot.com/plugin']}
                    socketPaths={['wss://dr1-bot-welton.bimariobot.com/socket', 
                    'wss://dr2-bot-welton.bimariobot.com/socket']}
                />
            </GlobalServices>
        ),
    },
    {
        path: 'caperberryapp',
        acctId: 3,
        botId: 82,
        dbProfile: 'mario.production',
        component: (
            <GlobalServices
                acctId={3}
                botId={82}
                apiPaths={['https://caperberryapp.hihi2u.com/nodeApi']}
            >
                <Main
                    acctId={3}
                    botId={82}
                    client="caperberryapp"
                    title="BTC365"
                    brandId="welton"
                    dbProfile="mario.production"
                    apiPaths={['https://caperberryapp.hihi2u.com/nodeApi']}
                    pluginPaths={['https://bot.bimariobot.com/plugin']}
                    socketPaths={['wss://dr1-bot-welton.bimariobot.com/socket', 
                    'wss://dr2-bot-welton.bimariobot.com/socket']}
                />
            </GlobalServices>
        ),
    },
    {
        path: 'strawberryappsl',
        acctId: 3,
        botId: 18,
        dbProfile: 'mario.production',
        component: (
            <GlobalServices
                acctId={3}
                botId={18}
                apiPaths={['https://strawberryapp.hihi2u.com/nodeApi']}
            >
                <Main
                    acctId={3}
                    botId={18}
                    client="strawberryappsl"
                    title="F1M1 SL"
                    brandId="welton"
                    dbProfile="mario.production"
                    apiPaths={['https://strawberryapp.hihi2u.com/nodeApi']}
                    pluginPaths={['https://bot.bimariobot.com/plugin']}
                    socketPaths={['wss://dr1-bot-welton.bimariobot.com/socket']}
                />
            </GlobalServices>
        ),
    },
    {
        path: 'cranberryappsl',
        acctId: 3,
        botId: 70,
        dbProfile: 'mario.production',
        component: (
            <GlobalServices
                acctId={3}
                botId={70}
                apiPaths={['https://strawberryapp.hihi2u.com/nodeApi']}
            >
                <Main
                    acctId={3}
                    botId={70}
                    client="cranberryappsl"
                    title="F1M2 SL"
                    brandId="welton"
                    dbProfile="mario.production"
                    apiPaths={['https://strawberryapp.hihi2u.com/nodeApi']}
                    pluginPaths={['https://bot.bimariobot.com/plugin']}
                    socketPaths={['wss://dr1-bot-welton.bimariobot.com/socket']}
                />
            </GlobalServices>
        ),
    },
    {
        path: 'mulberryappsl',
        acctId: 3,
        botId: 71,
        dbProfile: 'mario.production',
        component: (
            <GlobalServices
                acctId={3}
                botId={71}
                apiPaths={['https://strawberryapp.hihi2u.com/nodeApi']}
            >
                <Main
                    acctId={3}
                    botId={71}
                    client="mulberryappsl"
                    title="F1M3 SL"
                    brandId="welton"
                    dbProfile="mario.production"
                    apiPaths={['https://strawberryapp.hihi2u.com/nodeApi']}
                    pluginPaths={['https://bot.bimariobot.com/plugin']}
                    socketPaths={['wss://dr1-bot-welton.bimariobot.com/socket']}
                />
            </GlobalServices>
        ),
    },
    {
        path: 'blueberryappsl',
        acctId: 4,
        botId: 63,
        dbProfile: 'mario.production',
        component: (
            <GlobalServices
                acctId={4}
                botId={63}
                apiPaths={['https://strawberryapp.hihi2u.com/nodeApi']}
            >
                <Main
                    acctId={4}
                    botId={63}
                    client="blueberryappsl"
                    title="T1M1 SL"
                    brandId="vpo"
                    dbProfile="mario.production"
                    apiPaths={['https://strawberryapp.hihi2u.com/nodeApi']}
                    pluginPaths={['https://bot.bimariobot.com/plugin']}
                    socketPaths={['wss://dr1-bot-vpo.bimariobot.com/socket']}
                />
            </GlobalServices>
        ),
    },
    {
        path: 'kiwiberryappsl',
        acctId: 5,
        botId: 76,
        dbProfile: 'mario.production',
        component: (
            <GlobalServices
                acctId={5}
                botId={76}
                apiPaths={['https://strawberryapp.hihi2u.com/nodeApi']}
            >
                <Main
                    acctId={5}
                    botId={76}
                    client="kiwiberryappsl"
                    title="P1M1 SL"
                    brandId="masuta"
                    dbProfile="mario.production"
                    apiPaths={['https://strawberryapp.hihi2u.com/nodeApi']}
                    pluginPaths={['https://bot.bimariobot.com/plugin']}
                    socketPaths={['wss://dr1-bot-masuta.bimariobot.com/socket']}
                />
            </GlobalServices>
        ),
    },
    {
        path: 'raspberryappsl',
        acctId: 4,
        botId: 77,
        dbProfile: 'mario.production',
        component: (
            <GlobalServices
                acctId={4}
                botId={77}
                apiPaths={['https://strawberryapp.hihi2u.com/nodeApi']}
            >
                <Main
                    acctId={4}
                    botId={77}
                    client="raspberryappsl"
                    title="P1M2 SL"
                    brandId="vpo"
                    dbProfile="mario.production"
                    apiPaths={['https://strawberryapp.hihi2u.com/nodeApi']}
                    pluginPaths={['https://bot.bimariobot.com/plugin']}
                    socketPaths={['wss://dr1-bot-vpo.bimariobot.com/socket']}
                />
            </GlobalServices>
        ),
    },
    {
        path: 'elderberryappsl',
        acctId: 5,
        botId: 85,
        dbProfile: 'mario.production',
        component: (
            <GlobalServices
                acctId={5}
                botId={85}
                apiPaths={['https://strawberryapp.hihi2u.com/nodeApi']}
            >
                <Main
                    acctId={5}
                    botId={85}
                    client="elderberryappsl"
                    title="P1M3 SL"
                    brandId="masuta"
                    dbProfile="mario.production"
                    apiPaths={['https://strawberryapp.hihi2u.com/nodeApi']}
                    pluginPaths={['https://bot.bimariobot.com/plugin']}
                    socketPaths={['wss://dr1-bot-masuta.bimariobot.com/socket']}
                />
            </GlobalServices>
        ),
    },
    {
        path: 'bilberryappsl',
        acctId: 4,
        botId: 87,
        dbProfile: 'mario.production',
        component: (
            <GlobalServices
                acctId={4}
                botId={87}
                apiPaths={['https://strawberryapp.hihi2u.com/nodeApi']}
            >
                <Main
                    acctId={4}
                    botId={87}
                    client="bilberryappsl"
                    title="J1M1 SL"
                    brandId="vpo"
                    dbProfile="mario.production"
                    apiPaths={['https://strawberryapp.hihi2u.com/nodeApi']}
                    pluginPaths={['https://bot.bimariobot.com/plugin']}
                    socketPaths={['wss://dr1-bot-vpo.bimariobot.com/socket']}
                />
            </GlobalServices>
        ),
    },
    {
        path: 'gojiberryappsl',
        acctId: 3,
        botId: 84,
        dbProfile: 'mario.production',
        component: (
            <GlobalServices
                acctId={3}
                botId={84}
                apiPaths={['https://strawberryapp.hihi2u.com/nodeApi']}
            >
                <Main
                    acctId={3}
                    botId={84}
                    client="gojiberryappsl"
                    title="J1M2 SL"
                    brandId="welton"
                    dbProfile="mario.production"
                    apiPaths={['https://strawberryapp.hihi2u.com/nodeApi']}
                    pluginPaths={['https://bot.bimariobot.com/plugin']}
                    socketPaths={['wss://dr1-bot-welton.bimariobot.com/socket']}
                />
            </GlobalServices>
        ),
    },
    {
        path: 'acaiberryappsl',
        acctId: 4,
        botId: 86,
        dbProfile: 'mario.production',
        component: (
            <GlobalServices
                acctId={4}
                botId={86}
                apiPaths={['https://strawberryapp.hihi2u.com/nodeApi']}
            >
                <Main
                    acctId={4}
                    botId={86}
                    client="acaiberryappsl"
                    title="J1M3 SL"
                    brandId="vpo"
                    dbProfile="mario.production"
                    apiPaths={['https://strawberryapp.hihi2u.com/nodeApi']}
                    pluginPaths={['https://bot.bimariobot.com/plugin']}
                    socketPaths={['wss://dr1-bot-vpo.bimariobot.com/socket']}
                />
            </GlobalServices>
        ),
    },
    {
        path: 'yellowberryappsl',
        acctId: 3,
        botId: 88,
        dbProfile: 'mario.production',
        component: (
            <GlobalServices
                acctId={3}
                botId={88}
                apiPaths={['https://strawberryapp.hihi2u.com/nodeApi']}
            >
                <Main
                    acctId={3}
                    botId={88}
                    client="yellowberryappsl"
                    title="F1M5 SL"
                    brandId="welton"
                    dbProfile="mario.production"
                    apiPaths={['https://strawberryapp.hihi2u.com/nodeApi']}
                    pluginPaths={['https://bot.bimariobot.com/plugin']}
                    socketPaths={['wss://dr1-bot-welton.bimariobot.com/socket']}
                />
            </GlobalServices>
        ),
    },
    {
        path: 'baneberryappsl',
        acctId: 3,
        botId: 89,
        dbProfile: 'mario.production',
        component: (
            <GlobalServices
                acctId={3}
                botId={89}
                apiPaths={['https://strawberryapp.hihi2u.com/nodeApi']}
            >
                <Main
                    acctId={3}
                    botId={89}
                    client="baneberryappsl"
                    title="B1MX ENG SL"
                    brandId="welton"
                    dbProfile="mario.production"
                    apiPaths={['https://strawberryapp.hihi2u.com/nodeApi']}
                    pluginPaths={['https://bot.bimariobot.com/plugin']}
                    socketPaths={['wss://dr1-bot-welton.bimariobot.com/socket']}
                />
            </GlobalServices>
        ),
    },
    {
        path: 'caperberryappsl',
        acctId: 3,
        botId: 90,
        dbProfile: 'mario.production',
        component: (
            <GlobalServices
                acctId={3}
                botId={90}
                apiPaths={['https://strawberryapp.hihi2u.com/nodeApi']}
            >
                <Main
                    acctId={3}
                    botId={90}
                    client="caperberryappsl"
                    title="B1MX CNY SL"
                    brandId="welton"
                    dbProfile="mario.production"
                    apiPaths={['https://strawberryapp.hihi2u.com/nodeApi']}
                    pluginPaths={['https://bot.bimariobot.com/plugin']}
                    socketPaths={['wss://dr1-bot-welton.bimariobot.com/socket']}
                />
            </GlobalServices>
        ),
    },
    {
        path: 'gb2bc',
        acctId: 7,
        botId: 68,
        dbProfile: 'mario.production',
        component: (
            <GlobalServices
                acctId={7}
                botId={68}
                apiPaths={['https://strawberryapp.hihi2u.com/nodeApi']}
            >
                <Main
                    acctId={7}
                    botId={68}
                    client="gb2bc"
                    title=""
                    brandId="gb"
                    dbProfile="mario.production"
                    apiPaths={['https://strawberryapp.hihi2u.com/nodeApi']}
                    pluginPaths={['https://bot.bimariobot.com/plugin']}
                    socketPaths={['wss://dr1-bot.bimariobot.com/socket',  
                    'wss://dr2-bot.bimariobot.com/socket']}
                />
            </GlobalServices>
        ),
    },
    {
        path: 'sgb',
        acctId: 8,
        botId: 69,
        dbProfile: 'mario.production',
        component: (
            <GlobalServices
                acctId={8}
                botId={69}
                apiPaths={['https://strawberryapp.hihi2u.com/nodeApi']}
            >
                <Main
                    acctId={8}
                    botId={69}
                    client="sgb"
                    title=""
                    brandId="bi"
                    dbProfile="mario.production"
                    apiPaths={['https://strawberryapp.hihi2u.com/nodeApi']}
                    pluginPaths={['https://bot.bimariobot.com/plugin']}
                    socketPaths={['wss://dr1-bot.bimariobot.com/socket',  
                    'wss://dr2-bot.bimariobot.com/socket']}
                />
            </GlobalServices>
        ),
    },
];
